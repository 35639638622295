import axios from '@/api/api_config'
import router from '@/router'
import * as jwt from 'jsonwebtoken'

//登录
export const loginUser=async(login)=>
{
    return await axios.post('UserInfo/Login',login)
}

const key="token110"

//从浏览器存储获取的token值
export const getToken=()=>{
    return sessionStorage.getItem(key)
}

//清除token
export const logOut=()=>{
    sessionStorage.clear()
    //localStorage.removeItem(key)
    router.replace('/')
}

//检查token过期时间
export const IsTokenVaild=()=>{
    
    //判断是否有登录
    let user =MyLoginInfo()// window.localStorage.getItem('MyUser')
    if (user == null) return false

    //判断是否有token
    const token=sessionStorage.getItem(key) //localStorage.getItem(key)
    
    if(!token) return false
    const dec=jwt.decode(token)
    //时间戳分为10位，秒级，和13位，毫秒级
    const datenow=Date.now()
    const exptime=dec.exp*1000

    return datenow<=exptime
}

//返回用户登录信息
export const MyLoginInfo=()=>
{
    let user =sessionStorage.getItem('MyUser');
        if (user == null) return null
        else return JSON.parse(user)
}