<template>
  <div class="common-layout">
    <el-container>
      <el-header class="maintop">    
          <el-row>
            <el-col :span="5" class="titTxt">
              <h3>
                ES安防报警管理系统
              </h3>
            </el-col>
            <el-col :span="7">
              <div></div>
            </el-col>
            <el-col :span="12" class="txtRight">
              <el-avatar :size="28">
                <el-icon>
                  <User />
                </el-icon>
              </el-avatar>
              <el-dropdown style="margin: 5px 0 0 5px;color:#fff">
                <span>
                  {{ myUserinfo.userName }}<el-icon class="el-icon--right"><arrow-down /></el-icon>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>        
                    <el-dropdown-item @click="$router.push('/main/editpass')">修改密码</el-dropdown-item>  
                    <el-dropdown-item @click="BtnExit">退出系统</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>

            </el-col>
          </el-row>
           <!-- <el-radio-group v-model="isCollapse">
            <el-radio-button :label="true">开</el-radio-button>
            <el-radio-button :label="false">合</el-radio-button>
          </el-radio-group> -->
      </el-header>

      <el-container>
        <el-aside class="menuleft">
          <el-menu active-text-color="#ffd04b" def background-color="#011a35" class="menuleft" text-color="#fff"
           :router=true 
           :default-active="$route.path"
            :collapse="isCollapse" 
            :unique-opened="true">
            <el-sub-menu index="1">
              <template #title>
                <el-icon>
                  <Setting />
                </el-icon>
                <span>系统管理</span>
              </template>
              <el-menu-item index="/main/index" @click="$router.push('/main/index')"><el-icon>
                  <Edit />
                </el-icon>仪表盘
              </el-menu-item>
              <el-menu-item index="/main/edituser" @click="$router.push('/main/edituser')"><el-icon>
                  <Edit />
                </el-icon>基本信息
              </el-menu-item>
              <!-- <el-menu-item index="/main/editpass" @click="$router.push('/main/editpass')"><el-icon>
                  <Key />
                </el-icon>修改密码
              </el-menu-item> -->
              <el-menu-item index="/main/userlist" @click="$router.push('/main/userlist')">
                <el-icon>
                  <User />
                </el-icon>会员管理
              </el-menu-item>
              <el-menu-item index="/main/smslog" @click="$router.replace('/main/smslog')"><el-icon>
                  <ChatDotSquare />
                </el-icon>短信记录
              </el-menu-item>
              <el-menu-item index="/main/txtvoicelog" @click="$router.push('/main/txtvoicelog')"><el-icon>
                  <Iphone />
                </el-icon>语音电话记录
              </el-menu-item>
              <el-menu-item index="/main/syslog" @click="$router.push('/main/syslog')"><el-icon>
                  <Document />
                </el-icon>操作日志
              </el-menu-item>
            </el-sub-menu>
            <!-- <el-menu-item index="/main/devicelist" @click="$router.push('/main/devicelist')"><el-icon>
                  <Grid />
                </el-icon>设备管理
              </el-menu-item> -->

            <el-sub-menu index="2" @click="$router.push('/main/devicelist')">
              <template #title>
                <el-icon>
                  <OfficeBuilding />
                </el-icon>
                <span>设备管理</span>
              </template>
             <el-menu-item index="/main/devicelist" @click="$router.push('/main/devicelist')"><el-icon>
                  <Grid />
                </el-icon>设备列表</el-menu-item>
              <!-- <el-menu-item index="/main/deviceadd" @click="$router.replace('/main/deviceadd')"><el-icon>
                  <AddLocation />
                </el-icon>添加设备</el-menu-item>  -->
            </el-sub-menu> 

            <el-sub-menu index="3">
              <template #title>
                <el-icon>
                  <CreditCard />
                </el-icon>
                <span>支付管理</span>
              </template>
              <el-menu-item index="/main/payrecord" @click="$router.push('/main/payrecord')"><el-icon>
                  <Money />
                </el-icon>充值记录</el-menu-item>
              <el-menu-item index="/main/payment" @click="$router.push('/main/payment')"><el-icon>
                  <Promotion />
                </el-icon>在线充值</el-menu-item>
          <el-menu-item v-if="myUserinfo.roleId == 1" index="/main/cashpay" @click="$router.push('/main/cashpay')"><el-icon>
                  <Promotion />
                </el-icon>手动充值</el-menu-item>   
            </el-sub-menu>
            <el-sub-menu v-if="myUserinfo.roleId == 1 || myUserinfo.roleId == 2" index="4">
              <template #title>
                <el-icon>
                  <Postcard />
                </el-icon>
                <span>其他功能</span>
              </template>
              <el-menu-item index="3-1" @click="$router.push('/main/index')"><el-icon>
                  <FirstAidKit />
                </el-icon>在线调试</el-menu-item>
              <el-menu-item index="3-2" @click="$router.push('/main/index')"><el-icon>
                  <UploadFilled />
                </el-icon>远程升级</el-menu-item>
                <el-menu-item index="/main/udptomqtt" @click="$router.push('/main/udptomqtt')"><el-icon>
                  <SwitchFilled />
                </el-icon>UDP转MQTT</el-menu-item>
            </el-sub-menu>
          </el-menu>
        </el-aside>

        <el-container>
          <el-main>
            <div class="h100 animate__animated animate__fadeIn">
              <router-view></router-view>
            </div>
          </el-main>
          <el-footer
            style="height:30px;line-height:200%;font-size: 12px;text-align: right;border-top: #ddd 1px solid;margin: 0;padding: 0;">
            <div>copyright 2023 by 1101920.com v2.0</div>
          </el-footer>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>
  
<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { ArrowDown } from '@element-plus/icons-vue'
import { MyLoginInfo } from '@/api/userlogin.js'
import 'animate.css'

const myUserinfo = ref()
const isCollapse = ref(false)
const store = useStore()

let user = MyLoginInfo()
if (user != null) {
  myUserinfo.value = user
}

//退出
const BtnExit = () => {
  store.dispatch("authModuls/logout")
}
</script> 
  
  
<style>  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 10px;
  }

  .box-card {
    width: 100%;
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: #ddd 1px solid;
    height: 40px;
  }
</style>