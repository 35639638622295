<template>
  <div class="common-layout" style="background: #072a50;">
    <el-container>
      <el-header class="loginTit">ES安防报警管理系统</el-header>
      <el-main>
        <el-row class="h100">
          <el-col :span="12" class="loginleft">
          </el-col>
          <el-col :span="12" class="loginright">
            <div class="loginbox">
              <div class="loginboxin">
                <div class="tit">ES安防报警管理系统</div>
                <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm"
                  :size="formSize" status-icon>
                  <el-form-item label="用户名" prop="UserName">
                    <el-input v-model="ruleForm.UserName" />
                  </el-form-item>

                  <el-form-item label="密 码" prop="Password">
                    <el-input v-model="ruleForm.Password" type="password" show-password />
                  </el-form-item>

                  <el-form-item>
                    <el-button  style="width:90px"  type="primary" @click="submitForm(ruleFormRef)">
                      登录
                    </el-button>
                    <el-button @click="subReg">注册</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-main>
      <el-footer>
        <div class="copy">Copyright 1101920.com 2022-2023 <a href="https://beian.miit.gov.cn/" target="_blank"
            class="linkwhte">粤ICP备09074730号</a></div>
      </el-footer>
    </el-container>
  </div>

  <Register  ref="myRefs" />

</template>

<script setup>
import {  reactive, ref } from 'vue'
import { ElMessage } from 'element-plus'
import store from '@/store';
import Register from '@/views/home/RegView.vue'
const myRefs = ref();
store.dispatch("authModuls/logout")

const ruleFormRef = ref()
const formSize = ref('default')
const ruleForm = reactive({
  UserName: '',
  Password: ''
})


const rules = reactive({
  UserName: [
    { required: true, message: '请输入用户名', trigger: 'blur' },
    { min: 3, max: 24, message: '错误的用户名称', trigger: 'blur' },
  ],
  Password: [
    {
      required: true, message: '请输入密码', trigger: 'blur',
    },
  ],

})

const submitForm = async (formEl) => {
  if (!formEl) return

  await formEl.validate((valid) => {
    if (valid)
     {      
      //验证数据通过，进行登录请求
      store.dispatch('authModuls/userLoginAction',ruleForm) 
    }
    else {
      ElMessage({ message: '数据检测异常', type: 'warning', })
    }
  })
}

const subReg=()=>
{  
  myRefs.value.OpenReg(true)
}

</script>


<style scoped>
.loginTit {
  height: 40px;
  background-color: #011a35;
  color: #fff;
  text-align: left;
  padding-left: 10px;
  font-size: 16px;
  line-height: 250%;
}

.el-form-item {
  min-height: 36px;
}

.loginleft {
  background: url('@/images/loginleft.png') no-repeat center center;
  height: 100%;
}

.loginright {
  height: 100%;
  display: table;
}

.loginbox {
  width: 320px;
  height: 350px;

  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.loginbox .tit {
  font-size: 24px;
  color: #000;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: left;
}

.loginboxin {
  margin-top: 15px;
  width: 320px;
  height: 240px;
  border-radius: 15px;
  background-color: #fff;
  padding: 30px;
  font-size: 14px;
  margin: 0 auto;
}
</style>