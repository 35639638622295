import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { IsTokenVaild } from '@/api/userlogin.js'
import Maininfo from '../views/home/MainView.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/main',
    name: 'main',
    component: Maininfo,
    children: [
      {
        name: 'edituser',
        path: 'edituser',
        component: () => import('../views/home/UserinfoEdit.vue')
      },
      {
        name: 'index',
        path: 'index',
        component: () => import('../views/home/MainHome.vue')
      },
      {
        name: 'editpass',
        path: 'editpass',
        component: () => import('../views/home/UserPwdedit.vue')
      },
      {
        name: 'userlistall',
        path: 'userlist',
        component: () => import('../views/home/UserList.vue')
      },
      {
        name: 'userlist',
        path: 'userlist/:id',
        component: () => import('../views/home/UserList.vue')
      },
      {
      name: 'smslog',
      path: 'smslog',
      component: () => import('../views/loginfo/SmsLog.vue')
    },
    {
      name: 'txtvoicelog',
      path: 'txtvoicelog',
      component: () => import('../views/loginfo/txtvoiceLog.vue')
    },
    {
      name: 'syslog',
      path: 'syslog',
      component: () => import('../views/loginfo/sysLog.vue')
    },
    {
      name: 'userlistFirst',
      path: 'syslog/:id',
      component: () => import('../views/loginfo/sysLog.vue')
    }
  ,
  {
    name: 'devicelist',
    path: 'devicelist',
    component: () => import('../views/device/DeviceList.vue')
  }
  ,
  {
    name: 'deviceadd',
    path: 'deviceadd',
    component: () => import('../views/device/DeviceAdd.vue')
  }
  ,
  {
    name: 'payment',
    path: 'payment',
    component: () => import('../views/payment/paymentAdd.vue')
  }
  ,
  {
    name: 'payrecord',
    path: 'payrecord',
    component: () => import('../views/payment/payList.vue')
  }
  ,
  {
    name: 'cashpay',
    path: 'cashpay',
    component: () => import('../views/payment/CashPayAdd.vue')
  }
  ,
  {
    name: 'udptomqtt',
    path: 'udptomqtt',
    component: () => import('../views/udptomqtt/udpmqttView.vue')
  }
]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

//全局守卫（前，后） //不符合条件的退出登录
router.beforeEach((to, from, next) => {
  if (to.path == "/") {
    next()
  }
  else {
    if (!IsTokenVaild()) {
      next('/')
    }
    else {
      next()
    }
  }

})

export default router
