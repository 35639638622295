
import axios from "./api_config";

//用户注册
export const regUser = async (userinfo) => {
    return await axios.post('UserInfo/Register', userinfo)
}

//用户信息获取
export const getUser = async (id) => {
    return await axios.post('UserInfo/GetUser/' + id)
}

export const setUser = async (userinfo) => {
    return await axios.post('UserInfo/EditUser', userinfo)
}

//修改用户密码
export const editUserPass = async (pwdinfo) => {
    return await axios.post('UserInfo/EditPassword', pwdinfo)
}

//获取用户数据
export const getUserlist =async (sch) => {
    return await axios.post('UserInfo/GetList ',sch)

    // return axios.post('userinfo/GetList', sch).then((res) => {
    //     if (res.data) 
    //     {
    //         console.log(res.data)
    //         if (res.data.errNO == 0) 
    //         {
    //             return  res.data
    //           }
    //         else 
    //         {
    //             ElMessage({ message: res.data.msg, type: 'warning', })
    //         }
    //     }
    //     return null
    // })
}

//是否是手机号码
export const isMobile = (mobile) => {
    const reg = /^0?1[3|4|5|6|7|8][0-9]\d{8}$/;
    return reg.test(mobile);
}
//uuid
export const generateUUID = () => {
    var guid = "";
    for (var i = 1; i <= 32; i++) {
      var n = Math.floor(Math.random() * 16.0).toString(16);
      guid += n;
      if (i == 8 || i == 12 || i == 16 || i == 20) guid += "-";
    }
    return guid;
  }
  
//用户手动支付
export const UserCashPay=async(info)=>
{
    return await axios.post('Pay/CashPay', info)
}

//获取订单的状态
export const UserGetOrderStatus=async(uuid)=>
{
      return await axios.post('Payment/GetOrderStatus/'+ uuid)
}
