import axios from 'axios'
import { ElMessage } from 'element-plus'
import { getToken } from '@/api/userlogin'
import { Promise } from 'core-js'

//axios.defaults.baseURL = "/api/"
axios.defaults.baseURL = "https://api.1101920.com/api/"
axios.defaults.headers['x-Requested-with'] = "XMLHttpRequest"
axios.defaults.headers.post['Content-Type'] = "application/json"

//拦截器请求
axios.interceptors.request.use(options => {
  //添加token请求
  const jtwToken = getToken()
  if (jtwToken) {
    options.headers.Authorization = `Bearer ${jtwToken}`
  }
  return options
}, (error) => {
  return Promise.reject(error)
})

//响应拦截
axios.interceptors.response.use(
  options => {
    return options
  }, 
  error => 
  {
    if (error.code === 'ERR_NETWORK')
   {
      ElMessage({ message: '网络连接错误', type: 'warning' })
    }
     else
   {
     ElMessage({ message: error.response.data?error.response.data:"网络连接错误！", type: 'warning' })
   }
   return Promise.reject(error)
  }
)

export default axios