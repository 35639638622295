import { loginUser, logOut } from '@/api/userlogin.js'
import router from '@/router/index'
import { ElMessage } from 'element-plus'

const authModuls = {
    //告诉使用者调用需要加上命名空间
    namespaced: true,
    state: {
        //全局变量
        sigInState: {
            id: 0,
            userName: '',
            token: null,
            roleId :1,
            exp: Date.now()
        }
    },
    getters: {
        //计算属性
    },
    mutations: {
        //修改token
        userLogin(state, token) {
            state.sigInState.token = token
            sessionStorage.setItem("token110", token)    
            //localStorage.setItem("token110", token)
        },
        userEditId(state, id) {
            state.sigInState.id = id
        },
        userEditName(state, uname) {
            state.sigInState.userName = uname
        }
    },
    actions: {
        //异步修改
        async userLoginAction({ commit }, login) {
            const { data } = await loginUser(login)
            if (data) {
                if (data.errNO == 0) {
                    ElMessage({ message: '登录成功，' + data.data.userName, type: 'success', })
                    commit('userLogin', data.data.token)
                    commit('userEditId', data.data.id)
                    commit('userEditName', data.data.userName)                  
                    sessionStorage.setItem('MyUser',JSON.stringify(data.data))
                    router.replace('/main/index')
                }
                else {
                    ElMessage({ message: data.msg, type: 'error', })
                }
            }
            else
            {
                ElMessage({ message: '登录异常', type: 'error', })
            }


        },
        logout() {
            //退出系统
            logOut()
        }
    }
}

export default authModuls;
