<template >
  <el-drawer v-model=regOpen title="User register" :with-header="false" style="padding:20px;min-width: 500px;">
    <h3>新用户注册</h3>
    <br />
    <el-form ref="ruleRegRef" :model="regform" :rules="regRules">
      <el-form-item label="用户名" :label-width="formLabelWidth" prop="userName">
        <el-input v-model="regform.userName" />
      </el-form-item>
      <el-form-item label="手机号码" :label-width="formLabelWidth" prop="bindMobile">
        <el-input v-model="regform.bindMobile" />
      </el-form-item>
      <el-form-item label="密码" :label-width="formLabelWidth" prop="password">
        <el-input v-model="regform.password" type="password" show-password/>
      </el-form-item>
      <el-form-item label="确认密码" :label-width="formLabelWidth" prop="rePassword">
        <el-input v-model="regform.rePassword" type="password" show-password/>
      </el-form-item>
    </el-form>
    <br />
    <div style="padding-left: 100px;text-align: left;">
      <el-button style="width:90px;" type="primary" @click="onClickReg(ruleRegRef)" :icon="Edit">提交</el-button>
      <el-button @click="cancelReg">取消</el-button>
    </div>
  </el-drawer>
</template>
  
<script setup>
import { reactive, ref, defineExpose } from 'vue'
import { ElDrawer, ElMessage,  ElMessageBox } from 'element-plus'
import { regUser } from '@/api/userinfo.js'
import { showLoading, hideLoading } from '@/store/loading.js'
import { MyLoginInfo } from '@/api/userlogin.js'
import { Edit} from '@element-plus/icons-vue'
const regOpen = ref(false)
const ruleRegRef = ref()
const regform = reactive({
  userName: '',
  bindMobile: '',
  password: '',
  rePassword: '',
  parentUser: ''
})
const validatePass = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入密码'))
  }
  else {
    if (regform.rePassword !== '') {
      if (!ruleRegRef.value) return
      ruleRegRef.value.validateField('rePassword', () => null)
    }
    callback()
  }
}

const validatePass2 = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请再次输入密码'))
  } else if (value !== regform.password) {
    callback(new Error("两次输入的密码不一致"))
  } else {
    callback()
  }
}


//打开或关闭注册窗口
const OpenReg = (val) => {
  regOpen.value = val
}
defineExpose({
  OpenReg
})

//提交注册
const onClickReg = async (formEl) => {
  if (!formEl) return

  await formEl.validate(async (valid) => {
    if (valid) {

      //开始请求
      showLoading('申请中，请稍等... ...')   
      let user = MyLoginInfo()
    if (user != null)
     {
      regform.parentUser=user.userName
    }
    else
    {
       regform.parentUser=''
    }

      const { data } = await regUser(regform)    
      if (data) {

        if (data.errNO == 0) {      
          //注册成功提示
          openSucc()

          //关闭窗口
          cancelReg()

        }
        else {
          ElMessage({ message: data.msg, type: 'error', })
        }
      }
      else {
        ElMessage({ message: '网络异常', type: 'error', })
      }

      //关闭加载背景
      hideLoading()
    }
    else {
      ElMessage({ message: '请检查用户信息', type: 'error', })
    }
  })
}

//取消注册,关闭窗口
const cancelReg = () => {
  OpenReg(false)
}

//成功提示
const openSucc = () => {
  ElMessageBox.alert('用户【' + regform.userName + '】注册成功，可以开始使用该帐号了。', '消息提示', {
    confirmButtonText: 'OK',
    callback: () => {
      //确定后关闭    
      location.reload();
    },
  })
}
const regRules = reactive({
  userName: [
    { required: true, message: '请输入用户名', trigger: 'blur' },
    { min: 3, max: 24, message: '错误的用户名称', trigger: 'blur' },
  ],
  password: [{ required: true, message: '请输入密码', trigger: 'blur' }, { min: 6, max: 32, message: '密码长度不少于6', trigger: 'blur' },
  { validator: validatePass, trigger: 'blur' }],
  rePassword: [{ required: true, message: '请再次输入密码', trigger: 'blur' },
  { validator: validatePass2, trigger: 'blur' }],
  bindMobile: [{ required: true, message: '请输入手机号码', trigger: 'blur' } 
   ,{ min: 11, max: 11, message: '请输入正确的手机号码', trigger: 'blur' }]

})

const formLabelWidth = '100px'
</script>

<style scoped>
.el-form-item {
  min-height: 36px;
}
</style>