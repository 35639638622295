<template>
  <router-view />
</template>


<style>
body{background-color: #f9f9f9;}
*{  font-family: Avenir, Helvetica, Arial, sans-serif;
margin: 0;padding: 0;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  color: #2c3e50;
  height: 100%;
  width: 100%;
  position: absolute;
}
html,body,#app,.common-layout,.el-container,.main,.h100{ height: 100%; box-sizing: border-box; }
.el-menu{border: 0;}
.el-aside{background: #011a35;margin: 0}
.menuleft:not(.el-menu--collapse){width: 200px;}
.maintop{background:#011a35; color:#fff;}
.titTxt{letter-spacing: 1px;text-align: left;margin-top: 20px;}
.txtRight{text-align: right;font-size: 12px; margin-top: 20px;}
.copy{font-size:14px;color: #000;}
.copy a:link, .copy a:visited {text-decoration: none; color: #000;}
.el-footer{height: 30px;}
.txtcenter{text-align: center;}
</style>
